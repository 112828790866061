<template>
  <Spinner v-if="orderLoading" />

  <div class="order-details" v-else-if="order">
    <div class="between ai-c mb-30">
      <VButtonPageBack :callback="onPageBack" />

      <div class="d-flex gap-s">
        <VButton
          v-if="visibleBtnDeleteOrder && permissionReadOrderChangelog && !order.deleted_at"
          text="Удалить заказ"
          icon="remove"
          size="sm"
          @click.native="openModal('order-delete')"
        />

        <ItemChangelog :subject-id="order.id" :subject-type="subjectType" />
      </div>
    </div>

    <OrderCard class="mb-30" :order="order" is-detail>
      <template v-if="order.from_outer_reclamation" v-slot:reclamation>
        <InfoText
          label="Статус рекламации"
          :text="getReclamationStatusName(order.from_outer_reclamation.status)"
        />

        <InfoText label="Решение по рекламации">
          <ReclamationExternalResolution
            :resolution-id="order.from_outer_reclamation.decision"
          />
        </InfoText>
      </template>
    </OrderCard>

    <div
      v-if="visibleBtnBackAllStatus || visibleBtnNextAllStatus"
      class="d-flex jc-fe mb-30 gap-s"
    >
      <VButton
        v-if="visibleBtnBackAllStatus"
        text="Вернуть"
        color="orange"
        size="l"
        :icon="{ name: 'arrow-next', dir: '180' }"
        @click.native="openModalOrderStatusAll('prev')"
      />

      <VButton
        v-if="visibleBtnNextAllStatus"
        text="Передать"
        color="green"
        size="l"
        direction="row-reverse"
        icon="arrow-next"
        @click.native="openModalOrderStatusAll('next')"
      />
    </div>

    <OrderInfoBlock class="mb-30" />

    <!--  позиции  -->
    <Positions id="positions" class="mb-30">
      <template v-if="!!currentPositionId" v-slot:header-buttons>

        <VButton
          v-if="permissionCreateComment"
          text="Комментарии"
          icon="comment"
          size="sm"
          :is-notification="Boolean(comments.length)"
          @click.native="openModalPositionComment"
        />

        <VButton
          v-if="permissionPrintDocumentProduction"
          text="На печать"
          icon="print"
          size="sm"
          @click.native="openModal('print-position')"
        />
      </template>
    </Positions>

    <!--  этапы  -->
    <StageCreateBlock v-if="currentPositionId" id="stages" class="mb-30" />

    <!--  партии  -->
    <div id="consignments" class="box mb-30">
      <header class="box__header">
        <h3>Информация по партиям</h3>

        <VButton
          v-if="visibleBtnConsignmentCreate"
          text="Создать партию"
          icon="create"
          size="sm"
          color="primary-sm"
          :disabled="isEditStage || isCreateStage"
          @click.native="openModal('consignment-create')"
        />
      </header>

      <NoContentBox
        v-if="!positionConsignments.length"
        text="Партия не создана"
      />

      <div v-else class="consignment-card-list">
        <ConsignmentCard
          v-for="consignment in positionConsignments"
          :key="consignment.id"
          :consignment="consignment"
          :position="currentPositionActive"
          :stage-line="positionStatusLine.statuses"
          :position-idx="activePositionIndex"
          @goStageNext="consignmentGoStageNextHandler"
          @goSplit="consignmentGoSplitHandler"
          @remove="consignmentRemoveHandler"
          @update="consignmentUpdateHandler"
          @addDirtyWeight="consignmentAddDirtyWeightHandler"
          @addExecutor="consignmentAddExecutorHandler"
        />
      </div>
    </div>

    <!--  Этапы выполнения  -->
    <StageLineImplementation
      v-if="!!currentPositionId && statuses"
      id="stage-line-implementation"
    />

    <!-- Create consignment -->
    <ModalConsignmentCreate
      v-if="isModal === 'consignment-create'"
      :position="currentPositionActive"
      :stage-line="currentStageList"
      :count-material="countMaterialByArticle"
      @request="consignmentCreateHandler"
      @reject="closeModal"
    />

    <!-- changelog -->
    <BaseModal
      v-if="isModal === 'changelog'"
      title="Журнал изменений"
      class="modal--title-reset modal-changelog"
      @close="closeModal"
    >
      <Spinner v-if="$store.state.isSendingRequestModal" />

      <AppTable
        v-else
        class="modal__table modal__box custom-scroll"
        :columns="changelog.tableHead"
        :items="changelog.list"
        rowActive
        @rowClick="onClickChangelogItem"
      />
    </BaseModal>

    <!-- changelog detail -->
    <BaseModal
      v-if="isModal === 'changelog-detail'"
      title="Изменения"
      class="modal-changelog"
      @close="openModal('changelog')"
    >
      <AppTable
        class="modal__table modal__box custom-scroll"
        :columns="changelog.detail.tableHead"
        :items="changelog.detail.list"
      />
    </BaseModal>

    <!-- comments -->
    <BaseModal
      v-if="isModal === 'position-comment'"
      title="Комментарии"
      class="modal-comment"
      @close="closeModal"
    >
    <div class="modal__box custom-scroll">
      <div class="column gap-xs">
        <CardComment
          v-for="item in comments"
          :key="item.id"
          :comment="item"
          is-modal-card
        />
      </div>
    </div>
    </BaseModal>

    <!-- print -->
    <ModalPrint
      v-if="isModal === 'print-position'"
      :print-params="positionsPrintParams"
      :print-options="printOptions"
      init-print-type="passport-quality"
      @request="closeModal"
      @reject="closeModal"
    >
        <template>
            <VInput
                v-model="amount"
                label="Количество для частичной отгрузки"
                name="amount"
                :pattern="pattern.num"
                :maxValue="currentPositionActive.value"
            >
                <Validation for="pattern" key-text="num"/>
                <Validation for="maxValue" :count="currentPositionActive.value"/>
            </VInput>
        </template>
    </ModalPrint>

    <!-- order delete -->
    <ModalConfirmation
      v-if="isModal === 'order-delete'"
      title="Уверены что хотите удалить заказ?"
      @confirm="orderDeleteHandler"
      @reject="closeModal"
    />

    <!-- orderStatusAllNextOrBack -->
    <ModalConfirmation
      v-if="isModal === 'order-status-all'"
      :title="`Вы действительно хотите ${textModalOrderStatusAll}?`"
      @confirm="orderChangeStatusAll"
      @reject="closeModalOrderStatusAll"
    />

    <AnchorBar :anchor-list="anchorList" />
  </div>
</template>

<script>
import AnchorBar from '@/components/ui/AnchorBar/AnchorBar.vue'
import BaseModal from '@/components/ui/Modal/BaseModal'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import OrderInfoBlock from '@/components/production/OrderInfoBlock'
import StageCreateBlock from '@/components/production/StageCreateBlock'
import StageLineImplementation from '@/components/views/Production/OrderDetail/StageLineImplementation'
import ModalConsignmentCreate from '@/components/ui/Modal/Production/Consignment/ModalConsignmentCreate'
import OrderCard from '@/components/production/order/OrderCard'
import ConsignmentCard from '@/components/production/consignment/ConsignmentCard'
import Positions from '@/components/production/position/Positions'
import VButton from '@/components/simple/button/VButton'
import ModalPrint from '@/components/ui/Modal/ModalPrint'
import CardComment from '@/components/production/CardComment'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'
import NoContentBox from '@/components/production/NoContentBox'
import { ReclamationExternalResolution, reclamationExternalModel } from '@/entities/reclamation-external'
import InfoText from '@/components/simple/text/InfoText.vue'
import VInput from '@/components/Form/Vinput/VInput'
import Validation from '@/components/Form/Validation'

import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinModalHandler } from '@/mixins/modal/MixinModalHandler'

import { POSITION_PRINT_TYPE_LIST } from '@/const/production/position'
import { PATTERN_LIST } from '@/const/validation'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { goToPage, goToPrevPage } from '@/utils/router'
import OrderController from '@/controllers/production/Order/OrderController'
import { ItemChangelog } from '@/entities/changelog/'
import { orderModel } from '@/entities/order'

export default {
  name: 'OrderDetails',
  components: {
    ItemChangelog,
    InfoText,
    AnchorBar,
    BaseModal,
    ModalConsignmentCreate,
    ModalPrint,
    ModalConfirmation,
    Positions,
    CardComment,
    OrderCard,
    StageLineImplementation,
    StageCreateBlock,
    ConsignmentCard,
    OrderInfoBlock,
    VButton,
    VInput,
    Validation,
    VButtonPageBack,
    NoContentBox,
    ReclamationExternalResolution
  },
  mixins: [mixinModal, mixinModalHandler],
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },

  data () {
    return {
      printOptions: POSITION_PRINT_TYPE_LIST,
      modalOrderStatus: {
        // направление движения статуса заказа (next || back)
        direction: ''
      },

      // для скролла
      toPositionIndex: 0,

      pattern: PATTERN_LIST,

      amount: null
    }
  },

  computed: {
    ...mapGetters('orderDetail', {
      order: 'order',
      currentOrderId: 'currentOrderId',
      orderLoading: 'orderLoading',
      orderStatusId: 'orderStatusId',
      comments: 'comments',
      positions: 'positions',
      positionConsignments: 'positionConsignments',
      activePositionIndex: 'activePositionIndex',
      positionStatusLine: 'positionStatusLine',
      currentPositionActive: 'currentPositionActive',
      currentPositionId: 'currentPositionId',
      isEditPosition: 'isEditPosition',
      isCreateStage: 'isCreateStage',
      isEditStage: 'isEditStage',
      statuses: 'statuses',
      currentStageList: 'currentStageList'
    }),

    ...mapGetters({
      countMaterialByArticle: 'warehouse/countMaterialByArticle',
      currentUserRole: 'currentUserRole'
    }),

    ...mapGetters('permission', {
      permissionDestroyOrder: 'permissionDestroyOrder',
      permissionReadOrderChangelog: 'permissionReadOrderChangelog',
      permissionCreateConsignment: 'permissionCreateConsignment',
      permissionCreateComment: 'permissionCreateComment',
      permissionPrintDocumentProduction: 'permissionPrintDocumentProduction',
      permissionUpdatePosition: 'permissionUpdatePosition',
      permissionDestroyPosition: 'permissionDestroyPosition'
    }),

    subjectType: () => orderModel.CHANGELOG_SUBJECT_TYPE,

    // доступы
    visibleBtnDeleteOrder () {
      let isConsignments = false
      this.positions.forEach(item => {
        if (item.consignments?.length) isConsignments = true
      })
      return this.permissionDestroyOrder && !isConsignments
    },
    visibleBtnNextAllStatus () {
      switch (this.orderStatusId) {
        case 1: {
          switch (this.currentUserRole) {
            case 'admin':
            case 'director':
            case 'process-engineer': {
              if (this.checkPositions) return true
              else return false
            }
            default:
              return false
          }
        }
        case 3: {
          switch (this.currentUserRole) {
            case 'admin':
            case 'director':
              return true
            default:
              return false
          }
        }
        case 4: {
          switch (this.currentUserRole) {
            case 'admin':
            case 'director':
            case 'warehouse-manager':
            case 'warehouse-operator':
              return true
            default:
              return false
          }
        }
        default:
          return false
      }
    },
    visibleBtnBackAllStatus () {
      switch (this.orderStatusId) {
        case 6: {
          switch (this.currentUserRole) {
            case 'admin':
            case 'director':
            case 'process-engineer':
            case 'production-manager':
              return true
            default:
              return false
          }
        }
        case 5:
        case 4: {
          switch (this.currentUserRole) {
            case 'admin':
            case 'director':
            case 'warehouse-manager':
            case 'warehouse-operator':
              return true
            default:
              return false
          }
        }
        default:
          return false
      }
    },
    visibleBtnConsignmentCreate () {
      return this.permissionCreateConsignment
      // TODO скрывать кнопку если не ожидаются пружины
      // return this.currentPositionActive?.waiting_start && this.permissionCreateConsignment
    },
    // доступы

    hasPositions () {
      return this.order?.positions?.length
    },
    positionsPrintParams () {
      if (!this.currentPositionId) return null

      return {
        position_id: this.currentPositionId,
        amount: this.amount
      }
    },

    textModalOrderStatusAll () {
      switch (this.modalOrderStatus.direction) {
        case 'next': {
          switch (this.orderStatusId) {
            case 1:
              return 'передать заказ в статус "На запуск"'
            case 3:
              return 'передать заказ в статус "На отгрузку"'
            case 4:
              return 'передать заказ в статус "Успешно реализовано"'
            default:
              return ''
          }
        }
        case 'prev': {
          switch (this.orderStatusId) {
            case 6:
              return 'вернуть заказ в статус "Новые заказы"'
            case 4:
              return 'вернуть заказ в статус "Готово"'
            case 5:
              return 'вернуть заказ в статус "На отгрузку"'
            default:
              return ''
          }
        }
        default:
          return ''
      }
    },

    anchorList () {
      const list = [
        { label: 'Заказ', elId: `order-card--${this.order.id}` },
        { label: 'Позиции', elId: 'positions' }
      ]

      if (this.currentPositionId) {
        list.push({ label: 'Этапы', elId: 'stages' })
      }

      list.push({ label: 'Партии', elId: 'consignments' })

      if (this.currentPositionId) {
        list.push({ label: 'Выполнение', elId: 'stage-line-implementation' })
      }

      return list
    },

    getReclamationStatusName: () => (id) => reclamationExternalModel.getStatusNameBy(id),
    checkPositions () {
      if (!this.positions?.length) {
        return false
      }

      for (const item of this.positions) {
        if (!item?.responsible_id) return false
      }
      return true
    }
  },

  watch: {
    // TODO это зачем?
    'order.process' (val) {
      this.order.process = val
    },
    async id (id) {
      await this.getOrderOne({ id })

      if (this.hasPositions) {
        await this.getComments(this.currentPositionId)
      }
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.toPositionIndex = from.params.positionIndex ?? 0
    })
  },

  async mounted () {
    await this.getOrderOne({
      id: this.id,
      positionIndex: this.toPositionIndex
    })

    if (this.hasPositions) {
      await this.getComments(this.currentPositionId)
    }
  },

  beforeDestroy () {
    this.CLEAR_ORDER()
    this.SET_STAGE_STORE([])
    this.SET_CURRENT_POSITION_VALUE({ value: {} })
    this.SET_CURRENT_POSITION_FILES_DELETE(null)
    this.$store.commit('orderDetail/SET_STAGE_STORE', [])
    this.$store.commit('orderDetail/SET_POSITION_STATUS_LINE_VALUE', [])
    this.toPositionIndex = 0
  },

  methods: {
    ...mapMutations('orderDetail', [
      'UPDATE_ORDER',
      'TOGGLE_IS_EDIT_POSITION',
      'UPDATE_CONSIGNMENT',
      'REMOVE_CONSIGNMENT',
      'CLEAR_ORDER',
      'SET_STAGE_STORE',
      'SET_CURRENT_POSITION_VALUE',
      'SET_CURRENT_POSITION_FILES_DELETE',
      'ADD_CONSIGNMENT',
      'UPDATE_CONSIGNMENT_AFTER_SPLIT'
    ]),
    ...mapMutations('warehouse', ['COUNT_CALC_BY_ARTICLE']),
    ...mapMutations('scroll', {
      setScrollNodeId: 'SET_NODE_ID'
    }),
    ...mapActions('orderDetail', {
      getOrderOne: 'getOrderOne',
      changeOrderStatus: 'changeOrderStatus',
      changeMassStatus: 'changeMassStatus',
      createPosition: 'createPosition',
      removePosition: 'removePosition',
      getComments: 'getCommentAll',
      updateConsignment: 'updateConsignment',
      loadCurrentPositionActive: 'loadCurrentPositionActive'
    }),

    onPageBack () {
      // TODO route #hash
      this.setScrollNodeId(`order-card--${this.order.id}`)
      goToPrevPage()
    },

    // Order
    async orderDeleteHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        const response = await OrderController.destroy(this.order.id)
        if (response?.status !== 204) return
        await goToPage('OrderList')
      } finally {
        this.closeModal()
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    openModalOrderStatusAll (direction) {
      this.modalOrderStatus.direction = direction
      this.openModal('order-status-all')
    },
    closeModalOrderStatusAll () {
      this.closeModal()
      this.modalOrderStatus.direction = ''
    },
    async orderChangeStatusAll () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        if (this.orderStatusId === 1 || this.orderStatusId === 6) {
          let status = null
          if (this.orderStatusId === 1) status = 6
          else status = 1
          await this.changeOrderStatus({
            id: this.currentOrderId,
            status: status
          })
        } else {
          await this.changeMassStatus({
            id: this.currentOrderId,
            direction: this.modalOrderStatus.direction
          })
        }
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
        this.closeModalOrderStatusAll()
      }
    },
    openModalPositionComment () {
      this.getComments(this.currentPositionId)
      this.openModal('position-comment')
    },

    // consignments
    async consignmentCreateHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        await this.loadCurrentPositionActive()
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
        this.closeModal()
      }
    },
    async consignmentAddDirtyWeightHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
    },
    async consignmentAddExecutorHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
    },
    async consignmentGoStageNextHandler (data) {
      this.UPDATE_ORDER(data.order)
      this.UPDATE_CONSIGNMENT(data)
      await this.loadCurrentPositionActive()
    },
    async consignmentGoSplitHandler (data) {
      this.ADD_CONSIGNMENT(data)

      const updatedData = {
        id: data.current_consignment,
        value: data.value,
        weight_dirty: data.weight_dirty
      }

      this.UPDATE_CONSIGNMENT_AFTER_SPLIT(updatedData)
    },
    async consignmentRemoveHandler (consignment) {
      this.REMOVE_CONSIGNMENT(consignment.id)
      this.COUNT_CALC_BY_ARTICLE(consignment)
      await this.loadCurrentPositionActive()
    },
    async consignmentUpdateHandler (data, handlers) {
      const callback = async () => {
        await this.updateConsignment(data)
        await this.loadCurrentPositionActive()
      }

      await this.modalHandler(handlers, callback)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__table {
  margin-right: 16px;

  &::v-deep th {
    &:last-child {
      color: $color__green;
    }

    &:nth-last-child(2) {
      color: $color__red;
    }
  }
}
</style>
