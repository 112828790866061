<template>
  <BaseModal
    class="modal-print"
    title="Печать документов"
    @close="closeModalPrint"
  >
    <VForm
      class="d-flex flex-col ai-c"
      @request="createPrint"
    >
      <div class="mb-30">
        <VCheckbox
          v-model="checkRouteSheet"
          label="Маршрутный лист"
          class="mb-20"
        />

        <VCheckbox
          v-model="checkTaskForm"
          label="Бланк ТЗ"
        />

        <VCheckbox
          v-if="permissionPrintDocsOffConsignment && isInnerReclamation"
          v-model="checkActMaterial"
          label="Акт на списание материала"
          class="mb-20 mt-20"
        />

        <VCheckbox
          v-if="permissionPrintDocsOffConsignment && isInnerReclamation"
          v-model="checkActInternalReclamation"
          label="Акт разбора внутренней рекламации"
        />
      </div>

      <VButton
        text="Печать"
        class="m-center"
        color="primary"
        size="l"
        type="submit"
        :loading="$store.state.isSendingRequestModal"
      />
    </VForm>
  </BaseModal>
</template>

<script>
import { openPrint } from '@/utils/print/openPrint'
import BaseModal from '@/components/ui/Modal/BaseModal'
import VForm from '@/components/Form/VForm'
import VCheckbox from '@/components/Form/VCheckbox'
import VButton from '@/components/simple/button/VButton'
import { showAlertWarning } from '@/utils/store/alert'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalConsignmentPrint',
  components: {
    BaseModal,
    VForm,
    VButton,
    VCheckbox
  },
  props: {
    consignmentId: {
      type: [String, Number],
      required: true
    },
    isInnerReclamation: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    checkTaskForm: true,
    checkRouteSheet: true,
    checkActMaterial: false,
    checkActInternalReclamation: false
  }),

  computed: {
    ...mapGetters('permission', [
      'permissionPrintDocsOffConsignment'
    ])
  },

  methods: {
    async createPrint () {
      if (this.checkTaskForm || this.checkRouteSheet || this.checkActMaterial || this.checkActInternalReclamation) {
        await this.getPrintRequest()
      } else {
        showAlertWarning('Пожалуйста, выберете тип документа для печати')
      }
    },

    async getPrintRequest () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const params = {
          consignment_ids: [this.consignmentId],
          page1: this.checkRouteSheet ? 'Y' : null,
          page2: this.checkTaskForm ? 'Y' : null,
          page3: this.checkActMaterial ? 'Y' : null,
          page4: this.checkActInternalReclamation ? 'Y' : null
        }

        await openPrint('technical-task', params)
      } finally {
        this.closeModalPrint()
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    closeModalPrint () {
      this.$emit('close')
    }
  }
}
</script>
